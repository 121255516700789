import axios from 'axios';
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

export default {
  namespaced: true,
  state: {
    authenticated: false,
    user: {},
    token: '',
    tokenType: '',
  },
  getters: {
    authenticated(state) {
      return state.authenticated;
    },
    user(state) {
      return state.user;
    },
    token(state) {
      return state.token;
    },
    tokenType(state) {
      return state.tokenType;
    },
  },
  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value;
    },
    SET_USER(state, value) {
      state.user = value;
    },
    SET_TOKEN(state, value) {
      state.token = value;
    },
    SET_TOKEN_TYPE(state, value) {
      state.tokenType = value;
    },
  },
  actions: {
    async signIn({ commit }, credentials) {
      await axios.get('/sanctum/csrf-cookie');
      const response = await axios.post('/api/login', credentials);
      commit('SET_AUTHENTICATED', true);
      commit('SET_USER', response.data.data.user);
      commit('SET_TOKEN', response.data.data.access_token);
      commit('SET_TOKEN_TYPE', response.data.data.token_type);
    },

    logout({ commit }) {
      commit('SET_AUTHENTICATED', false);
      commit('SET_USER', {});
      commit('SET_TOKEN', '');
      commit('SET_TOKEN_TYPE', '');
    },
  },
};
