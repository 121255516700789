import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/DashboardView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/struktural',
    name: 'struktural',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/StrukturalView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/catur-dharma',
    name: 'catur-dharma',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/CaturDharma.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/visi',
    name: 'visi',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/VisiView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/misi',
    name: 'misi',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/MisiView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/milestone',
    name: 'milestone',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/MileStone.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/layanan',
    name: 'layanan',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/LayananView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/berita',
    name: 'berita',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/BeritaView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/esg',
    name: 'esg',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/EsgView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/siaran-pers',
    name: 'siaran-pers',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SiaranPers.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/tata-kelola-perusahaan',
    name: 'tata-kelola-perusahaan',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/TataKelolaPerusahaan.vue'
      ),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/public-contribution',
    name: 'public-contribution',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PublicContribution.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/live-instagram',
    name: 'live-instagram',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/LiveInstagramView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/camera',
    name: 'camera',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/CameraView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/banner-struk-online',
    name: 'banner-struk-online',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/BannerStrukOnlineView.vue'
      ),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/iklan-struk-online',
    name: 'iklan-struk-online',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/AdvertStrukOnlineView.vue'
      ),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/photo',
    name: 'photo',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PhotoView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/video',
    name: 'video',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/VideoView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/social-media',
    name: 'social-media',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SocialMedia.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/sejarah',
    name: 'sejarah',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SejarahView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/pengguna',
    name: 'pengguna',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/PenggunaView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/section-home',
    name: 'section-home',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SectionView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/karir',
    name: 'karir',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/KarirView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/email',
    name: 'email',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/MailBox.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/setting-hut-mms',
    name: 'setting-hut-mms',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/RundownView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/annual-report',
    name: 'annual-report',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/AnnualReportView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/doorprize',
    name: 'Doorprize',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/DoorprizeView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/perlomba-hut-mms',
    name: 'perlomba-hut-mms',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/LombaView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/undian',
    name: 'undian',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/UndianView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/ucapan',
    name: 'ucapan',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/UcapanView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/kehadiran',
    name: 'kehadiran',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/KehadiranView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/launching-web',
    name: 'launching-web',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/LaunchingWebView.vue'),
    meta: {
      middleware: 'auth',
    },
  },
  {
    path: '/signin',
    name: 'signin',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/SignIn.vue'),
    meta: {
      middleware: 'guest',
    },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/NotFound.vue'),
    meta: {
      middleware: 'guest',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _from, next) => {
  document.title = `${to.meta.title} - ${process.env.MIX_APP_NAME}`;
  if (to.meta.middleware == 'guest') {
    if (store.state.auth.authenticated) {
      next({ name: 'dashboard' });
    }
    next();
  } else {
    if (store.state.auth.authenticated) {
      next();
    } else {
      next({ name: 'signin' });
    }
  }
});

export default router;
