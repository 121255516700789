<template>
  <v-app dark>
    <v-navigation-drawer v-model="drawer" :mini-variant="miniVariant" :clipped="clipped" fixed app v-if="authenticated">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-img src="./assets/logo_header.png"></v-img>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item>
          <v-list-item-action>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item to="/" router exact active-class="bg-active" v-if="user.roles === 'administrator'">
          <v-list-item-icon>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group no-action prepend-icon="mdi-human-greeting-proximity" v-if="user.roles === 'administrator'">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> Profil</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(item, i) in itemProfile" :key="i" :to="item.to" router exact active-class="bg-active">
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item to="/email" router exact active-class="bg-active" v-if="user.roles === 'administrator'">
          <v-list-item-icon>
            <v-icon>mdi-email</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mailbox</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/layanan" router exact active-class="bg-active" v-if="user.roles === 'administrator'">
          <v-list-item-icon>
            <v-icon>mdi-face-agent</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Layanan</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item to="/camera" router exact active-class="bg-active">
          <v-list-item-icon>
            <v-icon>mdi-cctv</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Camera</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group no-action prepend-icon="mdi-newspaper" v-if="user.roles === 'administrator'">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> Aktivitas</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(item, i) in itemAktivitas" :key="i" :to="item.to" router exact active-class="bg-active">
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group no-action prepend-icon="mdi-domain" v-if="user.roles === 'administrator'">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> ESG</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(item, i) in itemESG" :key="i" :to="item.to" router exact active-class="bg-active">
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group no-action prepend-icon="mdi-image" v-if="user.roles === 'administrator'">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> Galeri</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(item, i) in itemGaleri" :key="i" :to="item.to" router exact active-class="bg-active">
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group no-action prepend-icon="mdi-cog-outline" v-if="user.roles === 'administrator'">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> Setting</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(item, i) in itemsMaster" :key="i" :to="item.to" router exact active-class="bg-active">
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <!-- <v-list-group no-action prepend-icon="mdi-finance" v-if="user.roles === 'administrator'">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> Laporan</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(item, i) in itemLaporan" :key="i" :to="item.to" router exact active-class="bg-active">
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group> -->

        <v-list-item to="/karir" router exact active-class="bg-active" v-if="user.roles === 'administrator'">
          <v-list-item-icon>
            <v-icon>mdi-account-credit-card</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Karir</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group no-action prepend-icon="mdi-cake-variant" v-if="user.roles === 'administrator'">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> HUT MMS</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(item, i) in itemHUT" :key="i" :to="item.to" router exact active-class="bg-active">
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-group no-action prepend-icon="mdi-paper-roll" v-if="user.roles === 'administrator'">
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title> Struk Online</v-list-item-title>
            </v-list-item-content>
          </template>
          <v-list-item v-for="(item, i) in itemStrukOnline" :key="i" :to="item.to" router exact
            active-class="bg-active">
            <v-list-item-content>
              <v-list-item-title v-text="item.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-divider></v-divider>
        <div class="pa-2">
          <v-btn block color="orange darken-4" dark @click="logout" :loading="processing">
            <v-icon left>mdi-logout</v-icon>
            Logout
          </v-btn>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="clipped" fixed app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-btn icon @click.stop="miniVariant = !miniVariant">
        <v-icon>mdi-{{ `chevron-${miniVariant ? 'right' : 'left'}` }}</v-icon>
      </v-btn>
      <v-btn icon @click.stop="clipped = !clipped">
        <v-icon>mdi-application</v-icon>
      </v-btn>
      <v-btn icon @click.stop="fixed = !fixed">
        <v-icon>mdi-minus</v-icon>
      </v-btn>
      <v-toolbar-title v-text="title" />
      <v-spacer />
      <div>
        <v-tooltip v-if="!$vuetify.theme.dark" bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="info" x-small fab @click="darkMode">
              <v-icon class="mr-1">mdi-moon-waxing-crescent</v-icon>
            </v-btn>
          </template>
          <span>Dark Mode On</span>
        </v-tooltip>

        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="info" x-small fab @click="darkMode">
              <v-icon color="yellow">mdi-white-balance-sunny</v-icon>
            </v-btn>
          </template>
          <span>Dark Mode Off</span>
        </v-tooltip>
      </div>
      <v-btn icon to="/signin" v-if="!authenticated">
        <v-icon>mdi-login</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-slide-y-transition>
          <router-view></router-view>
        </v-slide-y-transition>
      </v-container>
    </v-main>
    <v-footer :absolute="!fixed" app>
      <span>&copy; {{ new Date().getFullYear() }} | Astra Infra Toll | Tangerang - Merak</span>
      <v-spacer></v-spacer>
      <span>{{ `V.${appVersion}` }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import update from './mixins/update';
import { version } from '../package.json';
export default {
  name: 'App',
  computed: {
    ...mapGetters({
      authenticated: 'auth/authenticated',
      user: 'auth/user',
      token: 'auth/token',
    }),
  },
  data() {
    return {
      appVersion: version,
      processing: false,
      clipped: false,
      drawer: true,
      fixed: false,
      // itemLaporan: [
      //   {
      //     title: "Annual Report",
      //     to: "/annual-report",
      //   }
      // ],
      itemProfile: [
        {
          title: "Social Media",
          to: "/social-media"
        },
        {
          title: "Sejarah",
          to: "/sejarah"
        },
        {
          title: "Catur Darma",
          to: "/catur-dharma"
        },
        {
          title: "Visi",
          to: "/visi"
        },
        {
          title: "Misi",
          to: "/misi"
        },
        {
          title: "Manajemen",
          to: "/struktural"
        },
        {
          title: "Penghargaan",
          to: "/milestone"
        },
      ],
      itemGaleri: [
        {
          title: "Photo",
          to: "/photo"
        },
        {
          title: "Video",
          to: "/video"
        },
      ],
      itemAktivitas: [
        {
          title: "Berita",
          to: "/berita"
        },
        {
          title: "Siaran Pers",
          to: "/siaran-pers"
        },
      ],
      itemHUT: [
        {
          title: 'Setting',
          to: '/setting-hut-mms'
        },
        {
          title: 'Ucapan',
          to: '/ucapan'
        },
        {
          title: 'Lomba',
          to: '/perlomba-hut-mms'
        },
        {
          title: 'Doorprize',
          to: '/doorprize'
        },
        {
          title: 'Kehadiran',
          to: '/kehadiran'
        },
        {
          title: 'Undian',
          to: '/undian'
        },
        {
          title: 'Launching',
          to: '/launching-web'
        }
      ],
      itemsMaster: [
        {
          title: 'Home',
          to: '/section-home',
        },
        {
          title: 'Pengguna',
          to: '/pengguna',
        },
        // {
        //   title: 'Live Instagram',
        //   to: '/live-instagram'
        // }
      ],
      itemESG: [
        {
          title: 'Tentang ESG',
          to: '/esg',
        },
        {
          title: 'Tata Kelola Perusahaan',
          to: '/tata-kelola-perusahaan',
        },
        {
          title: 'Public Contribution',
          to: '/public-contribution',
        },
      ],
      itemStrukOnline: [
        {
          title: 'Banner',
          to: '/banner-struk-online',
        },
        {
          title: 'Iklan',
          to: '/iklan-struk-online',
        },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: 'MARGA MANDALASAKTI',
    };
  },
  mixins: [update],
  created() {
    document.addEventListener('swUpdated', this.updateAvailable, {
      once: true,
    });
  },
  mounted() {
    document.title = process.env.VUE_APP_NAME
  },
  methods: {
    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;
      this.refreshApp();
    },
    ...mapActions({
      signOut: 'auth/logout',
    }),
    async logout() {
      this.processing = true;
      let config = {
        headers: {
          Authorization: 'Bearer ' + this.token,
        },
      };
      await this.axios
        .post('/api/logout', {}, config)
        .then(() => {
          this.signOut();
          this.$router.push({ name: 'signin' });
          this.processing = false;
        })
        .catch(() => (this.processing = false));
    },
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
  },
};
</script>
